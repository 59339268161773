<template>
  <div>
    <header>
      <el-row
        type="flex"
        align="middle"
        style="background-color: #07509c"
        class="pd-y-7 pd-x-4"
        v-loading.fullscreen.lock="fullLoading"
        element-loading-background="rgba(255, 255, 255, 0.8)"
      >
        <el-col :span="12">
          <div>
            <router-link to="/">
              <img
                style="width: 96px"
                src="@/assets/logo-white.svg"
                alt="terak white logo"
              />
            </router-link>
          </div>
        </el-col>
        <el-col :span="12" class="text-right">
          <el-row type="flex" justify="end" align="middle">
            <!-- แจ้งเตือน -->
            <el-dropdown
              trigger="click"
              class="mg-r-4 mg-t-7"
              v-if="user.role == 60"
            >
              <span class="el-dropdown-link text-white cr-pointer">
                <el-badge
                  :value="unread"
                  :hidden="unread == null || unread == 0 ? true : false"
                  class="item"
                >
                  <i class="el-icon-message-solid" style="font-size: 24px"></i>
                </el-badge>
              </span>
              <el-dropdown-menu slot="dropdown">
                <div>
                  <div
                    class="is-flex js-between pd-x-5 pd-y-6"
                    style="border-bottom: 1px solid #e5e5e5"
                  >
                    <div>การแจ้งเตือน</div>
                    <div>
                      <a class="text-grey" @click="readAll()"
                        ><i class="fas fa-check"></i>
                        ทำเครื่องหมายว่าอ่านแล้ว</a
                      >
                    </div>
                  </div>
                  <div v-if="noti.length > 0">
                    <div
                      class="pd-x-5 pd-t-5 cr-pointer hover-noti"
                      :class="data.read == 0 ? 'bg-blue' : ''"
                      v-for="(data, index) in noti"
                      style="width: 500px"
                      :key="index"
                    >
                      <div
                        class="is-flex js-between ai-center pd-b-5"
                        style="border-bottom: 1px solid #e5e5e5"
                        @click="readNoti(data)"
                      >
                        <div>
                          <div class="box-image">
                            <avatar
                              :username="data.name"
                              :rounded="false"
                              :src="
                                `${
                                  data.profileImg != 'undefined' &&
                                  data.profileImg
                                    ? imgUrl + data.profileImg
                                    : ''
                                }`
                              "
                              :size="40"
                            >
                            </avatar>
                          </div>
                        </div>
                        <div
                          class="pd-x-5 text-grey font-18"
                          style="line-height: 18px"
                        >
                          {{ data.subject }}
                          <span class="font-weight-semibold text-dark">
                            {{ data.name }} {{ data.surname }}
                          </span>
                          {{ data.adverb }}
                          <span class="font-weight-semibold text-dark">{{
                            data.verb
                          }}</span>
                          เมื่อวันที่
                          <span class="font-weight-semibold text-dark">
                            {{ data.createdAt | dateTimeTh }}
                          </span>
                          <div>{{ data.createdAt | rangeFromNow }}</div>
                        </div>
                        <div style="width: 14px">
                          <i
                            class="fas fa-circle text-primary"
                            v-if="data.read == 0"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="pd-x-5 pd-t-5" style="width: 500px">
                    <p class="text-grey-light text-center">
                      "ไม่มีการแจ้งเตือน"
                    </p>
                  </div>
                  <div>
                    <div class="text-center pd-y-6">
                      <router-link to="/notification" target="_blank"
                        >ดูทั้งหมด</router-link
                      >
                    </div>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- แจ้งเตือน -->
            <!-- โปรไฟล์ -->
            <el-dropdown v-if="user != null" trigger="click">
              <span class="cr-pointer flex-display ai-center">
                <div class="box-image">
                  <avatar
                    :username="user.name"
                    :rounded="false"
                    :src="
                      `${
                        user.profileImg != 'undefined' && user.profileImg
                          ? imgUrl + user.profileImg
                          : ''
                      }`
                    "
                    :size="40"
                  >
                  </avatar>
                </div>
              </span>
              <el-dropdown-menu slot="dropdown" style="min-width: 300px">
                <el-row>
                  <el-col
                    :span="24"
                    class="is-flex flex-fill js-center pd-b-6 pd-x-4 pd-t-4"
                  >
                    <div>
                      <div class="flex-display js-center">
                        <div class="box-image">
                          <avatar
                            :username="user.name"
                            :rounded="false"
                            :src="
                              `${
                                user.profileImg != 'undefined' &&
                                user.profileImg
                                  ? imgUrl + user.profileImg
                                  : ''
                              }`
                            "
                            :size="60"
                          >
                          </avatar>
                        </div>
                      </div>
                    </div>
                    <div class="pd-l-6">
                      <h4 class="mg-b-7 mg-t-0">
                        {{ user.name }} {{ user.surname }}
                      </h4>
                      <p class="text-grey mg-y-7">{{ user.email }}</p>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <div class="pd-4 pd-b-6">
                      <router-link to="/admin-edit-profile" class="text-dark">
                        แก้ไขข้อมูลส่วนตัว
                      </router-link>
                    </div>
                    <div class="pd-x-4 pd-b-4">
                      <router-link to="/change-password" class="text-dark">
                        แก้ไขรหัสผ่าน
                      </router-link>
                    </div>
                    <div
                      class="pd-t-4 pd-x-4 border-top"
                      v-if="user.role == 50 || user.role == 60"
                    >
                      <router-link
                        :to="user.role == 50 ? '/log-system' : '/dashboard'"
                        class="text-grey"
                      >
                        เมนูผู้ดูแล
                      </router-link>
                    </div>
                    <div
                      @click="signout"
                      class="pd-4 pd-y-6 text-grey cr-pointer"
                    >
                      ออกจากระบบ
                    </div>
                  </el-col>
                </el-row>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- โปรไฟล์ -->
          </el-row>
        </el-col>
      </el-row>
      <div class="is-flex">
        <el-menu
          class="el-menu-vertical"
          style="background-color: #07509c"
          collapse
        >
          <el-menu-item
            @click="$router.push('/dashboard').catch(() => {})"
            index="1"
            :class="{
              'is-active': $route.name == 'Dashboard',
            }"
            v-if="user.role == 60"
          >
            <i class="fas fa-chart-line" title="ภาพรวม"></i>
            <span slot="title">ภาพรวม</span>
          </el-menu-item>
          <el-menu-item
            @click="$router.push('/list/answer?type=answer').catch(() => {})"
            index="3"
            :class="{
              'is-active': $route.query.type == 'answer',
            }"
            v-if="user.role == 60"
          >
            <i class="far fa-file-alt" title="จัดการเกณฑ์การประเมิน"></i>
            <span slot="title">จัดการเกณฑ์การประเมิน</span>
          </el-menu-item>
          <el-menu-item
            @click="
              $router.push('/list/answer?type=suggestion').catch(() => {})
            "
            index="2"
            :class="{
              'is-active': $route.query.type == 'suggestion',
            }"
            v-if="user.role == 60"
          >
            <i class="fas fa-tasks" title="จัดการคำแนะนำ"></i>
            <span slot="title">จัดการคำแนะนำ</span>
          </el-menu-item>

          <el-menu-item
            @click="$router.push('/user/list').catch(() => {})"
            index="4"
            :class="{
              'is-active':
                $route.name == 'UserList' ||
                $route.name == 'AdminViewStateMent' ||
                $route.name == 'ManageMenu',
            }"
            v-if="user.role == 60"
          >
            <i class="fas fa-users" title="จัดการข้อมูลเพื่อการวิเคราะห์"></i>
            <span slot="title">จัดการข้อมูลเพื่อการวิเคราะห์</span>
          </el-menu-item>

          <el-menu-item
            @click="$router.push('/privacy/user').catch(() => {})"
            index="5"
            :class="{
              'is-active': $route.name == 'PrivacyUser',
            }"
            v-if="user.role == 60"
          >
            <i
              class="fas fa-file-contract"
              title="ประวัติการเปลี่ยนแปลงคำยินยอม"
            ></i>
            <span slot="title">ประวัติการเปลี่ยนแปลงคำยินยอม</span>
          </el-menu-item>

          <el-menu-item
            @click="$router.push('/refer').catch(() => {})"
            index="6"
            :class="{
              'is-active':
                $route.name == 'refer' || $route.name == 'refer user',
            }"
            v-if="user.role == 60 || user.role == 40"
          >
            <i class="fas fa-qrcode" title="ข้อมูลโครงการ"></i>
            <span slot="title">ข้อมูลโครงการ</span>
          </el-menu-item>

          <el-menu-item
            @click="$router.push('/user-feedback').catch(() => {})"
            index="7"
            :class="{
              'is-active':
                $route.name == 'userFeedBack' || $route.name == 'view feedback',
            }"
            v-if="user.role == 60"
          >
            <i class="fas fa-star" title="จัดการการประเมินผล"></i>
            <span slot="title">จัดการการประเมินผล</span>
          </el-menu-item>

          <el-menu-item
            @click="$router.open('/create-room')"
            v-if="user.role == 60 || user.role == 40"
          >
            <i class="fas fa-trophy" title="Vote"></i>
            <span slot="title">ระบบโหวต</span>
          </el-menu-item>

          <el-menu-item @click="getToken()" v-if="user.role == 60">
            <i class="fas fa-award" title="EXIM Thailand Pavilion"></i>
            <span slot="title">EXIM Thailand Pavilion</span>
          </el-menu-item>

          <el-menu-item
            @click="$router.push('/log-system').catch(() => {})"
            index="8"
            :class="{
              'is-active': $route.name == 'LogSystem',
            }"
            v-if="user.role == 50"
          >
            <i class="el-icon-tickets" title="Transaction Log"></i>
            <span slot="title">รายการธุรกรรม</span>
          </el-menu-item>
          <el-menu-item
            @click="$router.push('/system/admin').catch(() => {})"
            index="9"
            :class="{
              'is-active': $route.name == 'SystemAdmin',
            }"
            v-if="user.role == 50"
          >
            <i class="fas fa-user-tie" title="ข้อมูลผู้ดูแลระบบ"></i>
            <span slot="title">ข้อมูลผู้ดูแลระบบ</span>
          </el-menu-item>
          <el-menu-item
            @click="$router.push('/system/user').catch(() => {})"
            index="10"
            :class="{
              'is-active':
                $route.name == 'SystemUser' ||
                $route.name == 'AdminViewStateMent',
            }"
            v-if="user.role == 50"
          >
            <i class="fas fa-users" title="จัดการข้อมูลเพื่อการวิเคราะห์"></i>
            <span slot="title">ข้อมูลผู้ใช้งาน</span>
          </el-menu-item>
          <el-menu-item
            @click="newTab()"
            :class="{
              'is-active': $route.name == 'ChangeLog',
            }"
            v-if="user.role == 50"
          >
            <i class="fas fa-question" title="What's new"></i>
            <span slot="title">Change Log</span>
          </el-menu-item>
        </el-menu>
        <div class="width-max pd-x-7" style="min-height: calc(100vh - 62px)">
          <slot />
        </div>
      </div>
    </header>
    <Cookie :pattern="2" />
  </div>
</template>
<script>
import { HTTP } from "@/service/axios";
import Avatar from "vue-avatar";
import Cookie from "@/components/auth/Cookie";
export default {
  components: {
    Avatar,
    Cookie,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      imgUrl: process.env.VUE_APP_IMAGE_URL,
      ptvUrl: process.env.VUE_APP_TPV_URL,
      noti: [],
      checkRead: false,
      unread: null,
      fullLoading: false,
    };
  },
  mounted() {
    if (this.user.role == 60) {
      this.getNotification();
    }
  },
  methods: {
    async getToken ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.post(`thailand/pavilion/auth/admin`).catch((e) => {
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
      });
      window.open(`${this.ptvUrl}User/Login?Token=${res.data.token}`, "_blank");
    },
    newTab() {
      let routeData = this.$router.resolve({ path: "/changelog" });
      window.open(routeData.href, "_blank");
    },
    readAll ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`read/all`)
        .then((result) => {
          if (result.data.success) {
            this.getNotification();
          }
        })
        .catch((e) => {
          console.log(`read/all ERROR ${e}`);
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        });
    },
    async readNoti(data) {
      try
      {
        HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        HTTP.put(`noti/read/${data.id}`).catch((e) => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        });
        this.getNotification();
        let routeData = this.$router.resolve({
          name: "PrivacyUser",
        });
        window.open(routeData.href, "_blank");
      } catch {
        console.log("readNoti ERROR");
      }
    },
    async getNotification() {
      try {
        let obj = {
          limit: 4,
          offset: 0,
        };
        HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        let res = await HTTP.post(`noti/consent`, obj).catch((e) => {
          console.log(`search/consent ERROR ${e}`);
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        });
        if (res.data.success) {
          this.noti = res.data.obj;
          this.unread = res.data.unread;
        }
      } catch {
        console.log("Get Notification ERROR");
      }
    },
    async signout() {
      this.fullLoading = true;
      setTimeout(() => {
        window.localStorage.eram.clear();
      }, 200);
      // this.$store.commit("SIGNOUT");
      window.localStorage.removeItem("eram");
      delete HTTP.defaults.headers.common.Authorization;
      //this.$router.push("/");
      setTimeout(() => {
        window.location.href = "/";
      }, 250);
    },
  },
};
</script>
